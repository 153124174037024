import { Observable, catchError, filter, map, of, startWith } from 'rxjs';

export type LoadingModel = { status: 'loading' };
export type ValueModel<V> = { status: 'loaded'; value: V };
export type ErrorModel<E> = { status: 'error'; error: E };

export type ViewModel<V, E> = LoadingModel | ValueModel<V> | ErrorModel<E>;
export type ViewModelStatus<V, E> = ViewModel<V, E>['status'];

export function viewModelFrom<V, E>(source: Observable<V>): Observable<ViewModel<V, E>> {
  return source.pipe(
    map(value => ({
      status: 'loaded' as const,
      value,
    })),
    startWith({ status: 'loading' as const }),
    catchError((error: E) =>
      of({
        status: 'error' as const,
        error,
      })
    )
  );
}

export function statusOf<R, E>(source: Observable<ViewModel<R, E>>): Observable<ViewModelStatus<R, E>> {
  return source.pipe(map(e => e.status));
}

export function errorsOf<R, E>(source: Observable<ViewModel<R, E>>): Observable<E> {
  return source.pipe(
    filter((e): e is ErrorModel<E> => e.status === 'error'),
    map(e => e.error)
  );
}

export function valueOf<R, E>(source: Observable<ViewModel<R, E>>): Observable<R> {
  return source.pipe(
    filter((e): e is ValueModel<R> => e.status === 'loaded'),
    map(e => e.value)
  );
}
